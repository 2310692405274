* {
  font-family: "Barlow", sans-serif;
}

.player-input {
  text-align: center;
  outline: none;
  box-shadow: none;
  transition: none;
}

.player-input:focus,
.player-input:focus-visible,
.player-input:focus-within {
  outline: none !important;
  box-shadow: none !important;
}

.vl {
  border-left: 3px solid #0b5ed7;
  height: 100%;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
  z-index: -1;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #333333;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #888 #333333;
}

body {
  zoom: 1.35;
}

#confetti {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 9999;
}

.confetti {
  position: absolute;
  width: 12px;
  height: 12px;
  background: #ff0;
  opacity: 0;
  transform: rotate(45deg);
  animation: fall 3.5s forwards;
}

@keyframes fall {
  0% {
    transform: translateY(-100lvh) rotate(0deg);
    opacity: 0;
  }
  1% {
    opacity: 0.9;
  }
  99% {
    opacity: 0.2;
  }
  100% {
    transform: translateY(105lvh) rotate(800deg);
    opacity: 0;
  }
}
